import { NgClass, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FeatherModule } from 'angular-feather';
import { Dialog } from '../../models/dialog.model';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
  standalone: true,
  imports: [FeatherModule, NgIf, NgClass],
})
export class AlertModalComponent implements OnInit {
  botones: any[] = [];
  configModal: any = {
    background: '',
    color: '',
    icon: '',
    btn: '',
  };

  constructor(
    public dialogRef: MatDialogRef<AlertModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Dialog
  ) {
    this.changeConfigModal();
  }

  ngOnInit(): void {
    this.botones = this.data.botones;

    if (this.botones?.length === 0) {
      this.botones.push({
        texto: 'Aceptar',
        clase: 'btn-danger',
      });
    }
  }

  changeConfigModal() {
    switch (this.data.tipo) {
      case 'error':
        (this.configModal.background = '#f9e2f0'),
          (this.configModal.color = 'text-danger'),
          (this.configModal.icon = 'x'),
          (this.configModal.btn = 'btn-danger');
        break;
      case 'success':
        (this.configModal.background = '#d7ebc0'),
          (this.configModal.color = 'text-success'),
          (this.configModal.icon = 'check'),
          (this.configModal.btn = 'btn-success');
        break;
      case 'info':
        (this.configModal.background = '#e2f1fc'),
          (this.configModal.color = 'text-primary'),
          (this.configModal.icon = 'info'),
          (this.configModal.btn = 'btn-primary');
        break;
      case 'warning':
        (this.configModal.background = '#ffebb0'),
          (this.configModal.color = 'text-warning'),
          (this.configModal.icon = 'alert-triangle'),
          (this.configModal.btn = 'btn-warning');
        break;

      default:
        break;
    }
  }

  eventoBoton(evento: any): void {
    this.dialogRef.close(evento);
  }
}
