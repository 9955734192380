export class Dialog<T = any> {
  titulo: string;
  mensaje: string;
  tipo: string;
  botones: any[];
  cancelButtonText: string;
  confirmButtonText: string;
  hasCancelButton: boolean;
  hasConfirmButton: boolean;
  callbackCancel: any;
  callbackConfirm: any;
  disableClose: boolean;
  componente: string;
  data?: T;
  classes?: string;

  constructor(data: any) {
    this.titulo = data.titulo ?? 'Atención';
    this.mensaje = data.mensaje ?? '';
    this.tipo = data.tipo ?? 'error';
    this.botones = data.botones ?? [];
    this.cancelButtonText = data.cancelButtonText ?? 'Cancelar';
    this.confirmButtonText = data.confirmButtonText ?? 'Aceptar';
    this.hasCancelButton = data.hasCancelButton ?? false;
    this.hasConfirmButton = data.hasConfirmButton ?? true;
    this.callbackCancel = data?.callbackCancel ?? null;
    this.callbackConfirm = data?.callbackConfirm ?? null;
    this.disableClose = data?.disableClose ?? true;
    this.componente = data?.componente ?? 'default';
    this.data = data?.data ?? null;
  }
}
